import _ from 'lodash-es'
import { DataAccessFactory } from './DataAccessFactory'
import { Resource } from './dataAccess'


class ApplicationFactory extends DataAccessFactory {

    lookup

    constructor() {
        super()

        this.defaultActions = {
            query: { method: 'GET', params: this.queryParams },
            save: { method: 'POST', params: { applicationId: '@applicationId' } },
            update: { method: 'PUT', params: { applicationId: '@applicationId' } },
            remove: { method: 'DELETE', params: { applicationId: '@applicationId', id: '@id' } } 
        }
        this.server = 'application'
        this.lookup = 'application/lookup'

        this.initialize()
    }

    initialize() {
        let resources: Array<Resource> = [
            {
                name: 'Application',
                url: 'application/:applicationId',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationStatus',
                url: '',
                actions: {
                    get: {
                        url: 'application/:applicationId/application_status',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('lastModified')
                        )
                    },
                    getAll: {
                        method: 'GET',
                        url: 'application/application_status',
                        isArray: true,
                        cache: true
                    },
                    archive: {
                        method: 'POST',
                        url: 'application/:applicationId/archive',
                        params: { applicationId: '@applicationId' }
                    },
                    unarchive: {
                        method: 'GET',
                        url: 'application/:applicationId/unarchive',
                    }
                }
            },
            {
                name: 'Program',
                url: 'application/:applicationId/program',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'Referrer',
                url: 'application/:applicationId/referrer',
                actions: {}
            },





            // ProfilePage
            {
                name: 'ProfilePage',
                url: 'application/:applicationId/profile',
                actions: {
                get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'aboutYou.specialDietDetails','aboutYou.comfHouseholdTypes'
                            )
                        )
                    }
                }
            },
            {
                name: 'AboutYou',
                url: 'application/:applicationId/about_you',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'specialDietDetails', 'comfHouseholdTypes'
                            )
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('specialDietDetails', 'comfHouseholdTypes')
                        ),
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'specialDietDetails', 'comfHouseholdTypes'
                            )
                        ),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'HostFamilyLetter',
                url: 'application/:applicationId/host_family_letter',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },

            // PersonalDetailsPage
            {
                name: 'PersonalDetailsPage',
                url: 'application/:applicationId/personal_details',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'driving.roadTypes',
                                'contact.preferredMethodContact'
                            ),
                            this.trans.convertToDate(
                                'availability.availabilityDateEarliest',
                                'availability.availabilityDateLatest',
                                'availability.availabilityDateLatestMax',
                                'availability.onHolidayFrom',
                                'availability.onHolidayTo',
                                'driving.licenseValidTo',
                                'driving.fullLicenseExpectedOn',
                                'driving.permanenteLicenseExpectedOn',
                                'driving.startedDrivingDate',
                                'driving.practicalTestPassed'
                            )
                        ),
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'Availability',
                url: 'application/:applicationId/availability',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'Driving',
                url: 'application/:applicationId/driving',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox('roadTypes')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('roadTypes')
                        ),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'FeeSheetInfo',
                url: '',
                actions: {
                    query: {
                        method: 'GET',
                        url: 'application/:applicationId/feeSheetInformations',
                        params: { applicationId: '@applicationId' },
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('validFrom', 'validTo')
                        )
                    },
                    save: {
                        method: 'POST',
                        url: 'application/:applicationId/feeSheetInformation',
                        params: { applicationId: '@applicationId' }
                    },
                    accept: {
                        method: 'POST',
                        url: 'application/:applicationId/acceptFeeSheet',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },

            // TravelAndProgramPage
            {
                name: 'TravelAndProgramPage',
                url: 'application/:applicationId/travel_and_program',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'passport.birthDate',
                                'passport.minBirthDate',
                                'passport.maxBirthDate',
                                'passport.passportExpiryDate',
                                'visa.enteredDate',
                                'visa.departedDate'
                            )
                        )
                    }
                }
            },
            {
                name: 'Prematch',
                url: 'application/:applicationId/prematch',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'Passport',
                url: 'application/:applicationId/passport',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'Visa',
                url: 'application/:applicationId/visa',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },

            // ChildcareExperiencePage
            {
                name: 'ChildcareExperiencePage',
                url: 'application/:applicationId/childcare_experience',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('dce.childcareExperience.dateStarted', 'dce.childcareExperience.dateEnded'),
                            this.trans.convertStringToCheckbox('preferences.specialNeed')
                        )
                    }
                }
            },
            {
                name: 'ChildcarePreferences',
                url: 'application/:applicationId/childcare_preferences',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('specialNeed')
                        ),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'PreviousExperience',
                url: 'application/:applicationId/previous_experience',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ChildcareExperience',
                url: 'application/:applicationId/documented_childcare_experience/:id',
                actions: {
                    save: {
                        method: 'POST',
                        url: 'application/:applicationId/documented_childcare_experience',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('dateStarted', 'dateEnded')
                        )
                    },
                    update: {
                        method: 'PUT',
                        url: 'application/:applicationId/documented_childcare_experience',
                        params: { applicationId: '@applicationId' },
                        transformRequest: this.trans.exclude('note')
                    },
                    unassociatedDceToReference: {
                        method: 'GET',
                        url: 'application/:applicationId/documented_childcare_experience/referenceType/:referenceType/associated/:oldReferenceTargetId',
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems(
                                (o) => `${o.childcareTypeDesc} - ${o.reference} -  ${o.hours} hours`,
                                'id')
                        )
                    },
                    allDceToReference: {
                        method: 'GET',
                        url: 'application/:applicationId/documented_childcare_experience/referenceType/:referenceType/all',
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems(
                                (o) => `${o.childcareTypeDesc} - ${o.reference} -  ${o.hours} hours`,
                                'id')
                        )
                    },
                }
            },

            // EducationAndWorkPage
            {
                name: 'EducationAndWorkPage',
                url: 'application/:applicationId/education_work',
                actions: {
                    get: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'history.dateFrom',
                                'history.dateUntil'
                            )
                        )
                    }
                }
            },
            {
                name: 'EducationAndWorkHistory',
                url: 'application/:applicationId/education_work_history/:id',
                actions: {
                    save: {
                        method: 'POST',
                        url: 'application/:applicationId/education_work_history',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'dateFrom',
                                'dateUntil'
                            )
                        )
                    }
                }
            },

            // MedicalAndCriminalPage
            {
                name: 'MedicalAndCriminalPage',
                url: 'application/:applicationId/medical_criminal',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'medical.medicalCondition',
                                'medical.medicalAid',
                                'medical.specialDietDetails',
                                'medical.allergies',
                                'medical.allergyAnimals'
                            ),
                            this.trans.convertToDate(
                                'covidVaccination.covidVaccinationDate1',
                                'covidVaccination.covidVaccinationDate2'
                            )
                        )
                    }
                }
            },
            {
                name: 'MedicalDetails',
                url: 'application/:applicationId/medical',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox(
                                'medicalCondition',
                                'medicalAid',
                                'specialDietDetails',
                                'allergies',
                                'allergyAnimals'
                            )
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('medicalCondition', 'medicalAid', 'specialDietDetails', 'allergies', 'allergyAnimals')
                        ),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'PreExistingMedicalConditionsDetails',
                url: 'application/:applicationId/preExistingMedicalConditions',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'SmokingDetails',
                url: 'application/:applicationId/smoking',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'CriminalDetails',
                url: 'application/:applicationId/criminal',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'CovidVaccinationDetails',
                url: 'application/:applicationId/covidVaccination',
                actions: {
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.exclude('note'),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'InterviewReport',
                url: 'application/:applicationId/interview/interview_report',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox('motivation.householdDuties'),
                            this.trans.convertToDate('about.interviewDate'),
                            this.trans.convertToDate('about.metInPersonDate')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: this.trans.convertCheckboxToString('motivation.householdDuties'),
                        params: { applicationId: '@applicationId' }
                    },
                    confirmMeeting: {
                        method: 'POST',
                        url: 'application/interview/confirmMeeting',
                    }
                }
            },
            {
                name: 'ApplicationStatusHistory',
                url: 'application/:applicationId/statusHistory',
                actions: {
                    query: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        isArray: true
                    }
                }
            },
            {
                name: 'ApplicationComments',
                url: 'application/:applicationId/comments',
                actions: {
                    query: {
                        method: 'GET',
                        params: {
                            applicationId: '@applicationId'
                        },
                        isArray: true
                    }
                }
            },
            {
                name: 'ApplicantProfile',
                url: 'application/:applicationId/applicant/profile',
                actions: {
                    query: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicantDataReplication',
                url: 'application/:applicationId/applicantDataReplication',
                actions: {
                    query: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('result.DateSent')
                        )
                    }
                }
            },
            {
                name: 'ApplicationRightFirstTime',
                url: 'application/:applicationId/rightFirstTime',
                actions: {
                    get: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    },
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationInfantQualified',
                url: 'application/:applicationId/infantQualified',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationIQOlderKids',
                url: 'application/:applicationId/iqOlderKids',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationOnHold',
                url: 'application/:applicationId/onHold',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationCertificate',
                url: 'application/:applicationId/suppressCertificate',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationReviewHealth',
                url: '',
                params: { applicationId: '@applicationId' },
                actions: {
                    getLatest: {
                        url: 'application/:applicationId/healthReviewStatus',
                        method: 'GET'
                    },
                    update: {
                        url: 'application/:applicationId/healthReviewStatus',
                        method: 'POST'                       
                    }
                }
            },
            {
                name: 'ApplicationShareLink',
                url: 'application/:applicationId/displayShareLink',
                actions: {
                    get: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationOnAllowPaperReference',
                url: 'application/:applicationId/onAllowPaperReference',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'SendBackToAgency',
                url: 'application/:applicationId/sendBackToAgency',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'Reinstate',
                url: 'application/:applicationId/reinstate',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    canReinstate: {
                        url: 'application/:applicationId/canReinstate',
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'RePublishShare',
                url: 'application/:applicationId/rePublishShare',
                actions: {
                    publsh: {
                        method: 'PUT',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'Readmit',
                url: 'application/:applicationId/readmit',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    canReadmit: {
                        url: 'application/:applicationId/canReadmit',
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationFlightDetails',
                url: '',
                actions: {
                    get: {
                        method: 'GET',
                        url: 'application/:applicationId/flightDates',
                        params: { applicationId: '@applicationId' },
                    },
                    query: {
                        method: 'GET',
                        url: 'application/:applicationId/arrivalDates',
                        params: { applicationId: '@applicationId' },
                        isArray: true
                    },
                    save: {
                        method: 'POST',
                        url: 'application/:applicationId/saveFlightDetails',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationTXPDetails',
                url: '',
                actions: {
                    get: {
                        method: 'GET',
                        url: 'application/:applicationId/TXPDetails',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(this.trans.convertToDate('txpDate'))
                    },
                    save: {
                        method: 'POST',
                        url: 'application/:applicationId/TXPDetails',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'AcceptApplication',
                url: '',
                actions: {
                    save: {
                        method: 'POST',
                        url: 'application/:applicationId/acceptApplication',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'PlacementMatchDetails',
                url: 'application/:applicationId/placementMatchDetails',
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('placementPackSentDate'),
                            this.trans.convertStringToCheckbox('isPlacementPackReceivedText')
                        ),
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'ApplicationPlacement',
                url: '',
                actions: {
                    visaDetails: {
                        method: 'GET',
                        url: 'application/:applicationId/applicationPlacementVisaDetails',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('visaAppointmentDate', 'secondVisaAppointmentDate')
                        )
                    },
                    allHostFamilyDetails: {
                        method: 'GET',
                        url: 'application/:applicationId/allHostFamilyDetails',
                        params: { applicationId: '@applicationId' },
                        isArray: true
                    },
                    savePlacementPackDetails: {
                        method: 'POST',
                        url: 'application/:applicationId/placementPackDetails',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'placementPackSentDate'
                            )
                        )
                    },
                    saveVisaDetails: {
                        method: 'POST',
                        url: 'application/:applicationId/applicationPlacementVisaDetails',
                        params: { applicationId: '@applicationId' }
                    },
                    flightDetails: {
                        method: 'GET',
                        url: 'application/:applicationId/applicationPlacementFlightDetails',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'departDate',
                                'arrivalDate',
                                'flightLegList.departDateTime',
                                'flightLegList.arriveDateTime'
                            )
                        )
                    },
                    flightShareDetail: {
                        method: 'GET',
                        url: 'application/:applicationId/applicationPlacementShareFlightDetail',
                        params: { applicationId: '@applicationId' },
                    },
                    saveFlightShareDetail: {
                        method: 'POST',
                        url: 'application/:applicationId/applicationPlacementFlightDetails',
                        params: { applicationId: '@applicationId' }
                    },
                    sharedFlightContactDetails: {
                        method: 'GET',
                        url: 'application/:applicationId/sharedFlightContactDetails',
                        params: { applicationId: '@applicationId' },
                        isArray: true
                    },
                    beforeDepartDetails: {
                        method: 'GET',
                        url: 'application/:applicationId/applicationPlacementBeforeDepartDetails',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'embassyAppointmentDate',
                                'appointmentDate',
                                'arrivalDate'
                            )
                        )
                    },
                    saveBeforeDepartDetails: {
                        method: 'POST',
                        url: 'application/:applicationId/applicationPlacementBeforeDepartDetails',
                        params: { applicationId: '@applicationId' }
                    },
                    beforeDepartStatus: {
                        method: 'GET',
                        url: 'application/:applicationId/applicationPlacementBeforeDepartStatus',
                        params: { applicationId: '@applicationId' }
                    },
                    saveBeforeDepartStatus: {
                        method: 'POST',
                        url: 'application/:applicationId/applicationPlacementBeforeDepartStatus',
                        params: { applicationId: '@applicationId' }
                    },
                    placementPrinted: {
                        method: 'GET',
                        url: 'application/:applicationId/isPlacementPackPrinted',
                        params: { applicationId: '@applicationId' }
                    },
                    isInvoicePrinted: {
                        method: 'GET',
                        url: 'application/:applicationId/isInvoicePrinted',
                        params: { applicationId: '@applicationId' }
                    },
                    invoicePrinted: {
                        method: 'POST',
                        url: 'application/:applicationId/invoicePrinted',
                        params: { applicationId: '@applicationId' }
                    },
                    placementInfo: {
                        method: 'GET',
                        url: 'application/:applicationId/applicationPlacementInfo',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('arrivalDate')
                        )
                    }
                }
            },
            {
                name: 'ProfileAndAtlasDetail',
                url: '',
                actions: {
                    query: {
                        method: 'GET',
                        url: 'application/:applicationId/profileAndAtlasDetail',
                        params: { applicationId: '@applicationId' }
                    },
                    update: {
                        method: 'POST',
                        url: 'application/:applicationId/profileAndAtlasDetail',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'AssignCPI',
                url: 'application/:applicationId/assignCPI',
                actions: {
                    get: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    },
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    query: {
                        method: 'GET',
                        url: 'application/getCPIList',
                        isArray: true
                    },
                }
            },
            {
                name: 'CancelApplication',
                url: 'application/:applicationId/cancelApplication',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    query: {
                        method: 'GET',
                        url: 'application/getCancelReasonList',
                        isArray: true
                    },
                }
            },
            {
                name: 'FailApplication',
                url: 'application/:applicationId/failApplication',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    query: {
                        method: 'GET',
                        url: 'application/getFailReasonList',
                        isArray: true
                    },
                }
            },
            {
                name: 'ArchiveApplicant',
                url: '',
                actions: {
                    query: {
                        method: 'GET',
                        url: 'application/getArchiveReasonList',
                        isArray: true
                    },
                }
            },
            {
                name: 'EditFormStatus',
                url: 'application/:applicationId/CanEditForm',
                actions: {
                    update: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    query: {
                        method: 'GET',
                        params: { applicationId: '@applicationId' }
                    }
                }
            },
            {
                name: 'Workqueues',
                url: 'application/workqueues/:workqueueId',
                params: { workqueueId: '@workqueueId' },
                actions: {
                    query: {
                        isArray: true
                    },
                    approveCertificate: {
                        url: 'application/approveCertificates',
                        method: 'Post'
                    }
                }
            },
            {
                name: 'CompletionCertificate',
                url: 'application/:applicationId/completionCertificateURL/:year',
                actions: {
                    completionCertUrl: {
                        method: 'GET',
                        params: { applicationId: '@applicationId', year: "@year" }
                    }
                }
            },
            {
                name: 'DOSLetter',
                url: 'application/:usApplicationId/dosLetterURL',
                actions: {
                    dosCertUrl: {
                        method: 'GET',
                        params: { usApplicationId: '@usApplicationId' }
                    }
                }
            },
            {
                name: 'RevertSection',
                url: 'application/:applicationId/revertSection',
                actions: {
                    decline: {
                        method: 'POST',
                        params: { applicationId: '@applicationId' }                       
                    },
                }
            },
            {
                name: 'ITep',
                url: 'application/:applicationId/itep/:id',
                actions: {
                    query: {
                        url: 'application/:applicationId/itep',
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('purchaseDate', 'result.testDate')
                        )
                    },
                    assign: {
                        url: 'application/:applicationId/itep/assign',
                        method: 'POST',
                        params: { applicationId: '@applicationId' }
                    },
                    unassign: {
                        url: 'application/:applicationId/itep/:id/unassign',
                        method: 'POST',
                        params: { applicationId: '@applicationId', id: "@id" }
                    },
                    satisfactory: {
                        url: 'application/:applicationId/itep/:id/satisfactory',
                        method: 'POST',
                        params: { applicationId: '@applicationId', id: "@id" }
                    },
                    unsatisfactory: {
                        url: 'application/:applicationId/itep/:id/unsatisfactory',
                        method: 'POST',
                        params: { applicationId: '@applicationId', id: "@id" }
                    },
                    void: {
                        url: 'application/:applicationId/itep/:id/void',
                        method: 'POST',
                        params: { applicationId: '@applicationId', id: "@id" }
                    },
                    reassign: {
                        url: 'application/:applicationId/itep/:id/reassign',
                        method: 'POST',
                        params: { applicationId: '@applicationId', id: "@id" }
                    },
                    lastComment: {
                        url: 'application/:applicationId/itep/lastComment',
                        method: 'GET',
                        params: { applicationId: '@applicationId' },
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('createDate')
                        )
                    }
                }
            },
            {
                name: 'iTepTests',
                url: 'application/iTepTests/:id',
                actions: {
                    available: {
                        url: 'application/iTepTests/available',
                        method: 'GET',
                        isArray: true
                    },
                    unassign: {
                        url: 'application/iTepTests/:id/unassign',
                        method: 'POST',
                        params: { id: '@id' }
                    }
                }
            },
            {
                name: 'iTepImports',
                url: 'application/iTepImports/:id',
                actions: {
                    query: {
                        method: 'GET',
                        isArray: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('importDate')
                        )
                    }
                }
            },
            {
                name: 'ApplicationLookup',
                url: '',
                actions: {
                    country: {
                        method: 'GET',
                        url: `${this.lookup}/country`,
                        isArray: true,
                        cache: true
                    },
                    countryApp: {
                        method: 'GET',
                        url: `${this.lookup}/country/app`,
                        isArray: true,
                        cache: false
                    },
                    contactReason: {
                        method: 'GET',
                        url: `${this.lookup}/contact_reason`,
                        isArray: true,
                        cache: true
                    },
                    hobbiesAndInterests: {
                        method: 'GET',
                        url: `${this.lookup}/hobbies_interests`,
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('hobbyName', 'hobbyId')
                        )
                    },
                    specialSkills: {
                        method: 'GET',
                        url: `${this.lookup}/special_skills`,
                        isArray: true,
                        cache: true
                    },
                    swimmingLevel: {
                        method: 'GET',
                        url: `${this.lookup}/swimming_level`,
                        isArray: true,
                        cache: true
                    },
                    qualityRatings: {
                        method: 'GET',
                        url: `${this.lookup}/quality_ratings`,
                        isArray: true,
                        cache: true
                    },
                    languages: {
                        method: 'GET',
                        url: `${this.lookup}/languages`,
                        isArray: true,
                        cache: true
                    },
                    maritalStatus: {
                        method: 'GET',
                        url: `${this.lookup}/marital_status`,
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('name', 'code')
                        )
                    },
                    religions: {
                        method: 'GET',
                        url: `${this.lookup}/religions`,
                        isArray: true,
                        cache: true
                    },
                    departureGatewaysAvailable: {
                        method: 'GET',
                        url: `${this.lookup}/application/:applicationId/departure_gateways_available`,
                        isArray: true,
                        cache: true
                    },
                    drivingFrequency: {
                        method: 'GET',
                        url: `${this.lookup}/driving_frequency`,
                        isArray: true,
                        cache: true
                    },
                    drivingLicenseType: {
                        method: 'GET',
                        url: `${this.lookup}/driving_license_type/:applicationId`,
                        isArray: true,
                        cache: true
                    },
                    programType: {
                        method: 'GET',
                        url: `${this.lookup}/program_type`,
                        isArray: true,
                        cache: true
                    },
                    roadType: {
                        method: 'GET',
                        url: `${this.lookup}/road_type`,
                        isArray: true,
                        cache: true
                    },
                    contactMethod: {
                        method: 'GET',
                        url: `${this.lookup}/contact_method`,
                        isArray: true,
                        cache: true
                    },
                    apexSubCodes: {
                        method: 'GET',
                        url: `${this.lookup}/apex_sub_codes`,
                        isArray: true,
                        cache: true
                    },
                    infantExperience: {
                        method: 'GET',
                        url: `${this.lookup}/infant_experience`,
                        isArray: true,
                        cache: true
                    },
                    childcareExperienceType: {
                        method: 'GET',
                        url: `${this.lookup}/childcare_experience_type`,
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler((data) =>
                            data.map((e) => <any>{ code: Number(e.code), name: e.name })
                        )
                    },
                    apexQualifications: {
                        method: 'GET',
                        url: `${this.lookup}/application/:applicationId/apex_qualifications/`,
                        isArray: true,
                        cache: true
                    },
                    aa: {
                        method: 'GET',
                        url: `${this.lookup}/history_type`,
                        isArray: true,
                        cache: true
                    },
                    aaa: {
                        method: 'GET',
                        url: `${this.lookup}/history_extra_work`,
                        isArray: true,
                        cache: true
                    },
                    aaaa: {
                        method: 'GET',
                        url: `${this.lookup}/history_extra_education`,
                        isArray: true,
                        cache: true
                    },

                    medicalConditions: {
                        method: 'GET',
                        url: `${this.lookup}/medical_conditions`,
                        isArray: true,
                        cache: true
                    },
                    medicalAid: {
                        method: 'GET',
                        url: `${this.lookup}/medical_aid`,
                        isArray: true,
                        cache: true
                    },
                    allergies: {
                        method: 'GET',
                        url: `${this.lookup}/allergies`,
                        isArray: true,
                        cache: true
                    },
                    animalAllergies: {
                        method: 'GET',
                        url: `${this.lookup}/animal_allergies`,
                        isArray: true,
                        cache: true
                    },
                    immunisations: {
                        method: 'GET',
                        url: `${this.lookup}/immunisations`,
                        isArray: true,
                        cache: true
                    },
                    dietaryRequirements: {
                        method: 'GET',
                        url: `${this.lookup}/dietary_requirements`,
                        isArray: true,
                        cache: true
                    },
                    comfHouseholdTypes: {
                        method: 'GET',
                        url: `${this.lookup}/comf_household_types`,
                        isArray: true,
                        cache: true
                    },
                    smokingFrequency: {
                        method: 'GET',
                        url: `${this.lookup}/smoking_frequency`,
                        isArray: true,
                        cache: true
                    },
                    herpes1Frequency: {
                        method: 'GET',
                        url: `${this.lookup}/herpes1Frequency`,
                        isArray: true,
                        cache: true
                    },
                    auPairOrganisation: {
                        method: 'GET',
                        url: `${this.lookup}/au_pair_organisation`,
                        isArray: true,
                        cache: true
                    },
                    dialingCodes: {
                        method: 'GET',
                        url: `${this.lookup}/country/dialingcodes/:countryCode`,
                        isArray: true,
                        cache: true,
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToListboxItems('description', 'iddCode')
                        )
                    },
                    specialNeed: {
                        method: 'GET',
                        url: `${this.lookup}/special_need`,
                        isArray: true,
                        cache: true
                    },
                    academicStatus: {
                        method: 'GET',
                        url: `${this.lookup}/academic-status`,
                        isArray: true,
                        cache: true
                    },
                    englishAbility: {
                        method: 'GET',
                        url: `${this.lookup}/english-ability`,
                        isArray: true,
                        cache: true
                    },
                    occupation: {
                        method: 'GET',
                        url: `${this.lookup}/occupation`,
                        isArray: true,
                        cache: true
                    },
                    passportStatus: {
                        method: 'GET',
                        url: `${this.lookup}/passport_status`,
                        isArray: true,
                        cache: true
                    },
                    visaStatus: {
                        method: 'GET',
                        url: `${this.lookup}/visa_status`,
                        isArray: true,
                        cache: true
                    },
                    houseChores: {
                        method: 'GET',
                        url: `${this.lookup}/house_chores`,
                        isArray: true,
                        cache: true
                    },
                    statuses: {
                        method: 'GET',
                        url: `${this.lookup}/statuses`,
                        isArray: true,
                        cache: true
                    },
                    frequency: {
                        method: 'GET',
                        url: `${this.lookup}/frequency`,
                        isArray: true,
                        cache: true
                    },
                    regularCheckUps: {
                        method: 'GET',
                        url: `${this.lookup}/regularCheckUps`,
                        isArray: true,
                        cache: true
                    },
                    neurodevelopmentalDisorder: {
                        method: 'GET',
                        url: `${this.lookup}/neurodevelopmentalDisorder`,
                        isArray: true,
                        cache: true
                    },
                    abuseAndAssault: {
                        method: 'GET',
                        url: `${this.lookup}/abuseAndAssault`,
                        isArray: true,
                        cache: true
                    },
                    selfHarm: {
                        method: 'GET',
                        url: `${this.lookup}/selfHarm`,
                        isArray: true,
                        cache: true
                    },
                    eatingDisorder: {
                        method: 'GET',
                        url: `${this.lookup}/eatingDisorder`,
                        isArray: true,
                        cache: true
                    },
                    counselling: {
                        method: 'GET',
                        url: `${this.lookup}/counselling`,
                        isArray: true,
                        cache: true
                    },
                    healthStatus: {
                        method: 'GET',
                        url: `${this.lookup}/healthStatus`,
                        isArray: true,
                        cache: true
                    }
                }
            },
            {
                name: 'ApplicationShareGuids',
                url: 'share/application/:applicationId/share-guids'
            },
            {
                name: 'ShareApplication',
                url: 'share/application/:applicationGuid',
            },
            {
                name: 'ShareBackgroundQuestions',
                url: 'share/background-questions/:backgroundQuestionsGuid',
            },
            {
                name: 'ShareChildcareExperience',
                url: 'share/childcare-experience/:childcareExperienceGuid',
                actions: {
                    get: {
                        method: 'GET',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox('childcarePreferences.specialNeed')
                        )
                    }
                }
            },
            {
                name: 'ShareInterviewReport',
                url: 'share/interview-report/:interviewReportGuid',
            },
            {
                name: 'ShareTelephoneReferenceCheck',
                url: 'share/telephone-reference-check/:telephoneReferenceCheckGuid',
            },
            {
                name: 'ShareShareApplication',
                url: 'share/share-application/:shareApplicationGuid',
                actions: {
                    get: {
                        method: 'GET',
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertStringToCheckbox('childcarePreferences.specialNeed')
                        )
                    }
                }
            },
            {
                name: 'ShareFeeSheet',
                url: 'share/fee-sheet/:feeSheetGuid',
            },
            {
                name: 'ApplicationData',
                url: 'application/:applicationId/data'
            },
            {
                name: 'CountrySettings',
                url: 'application/country/:countryCode/settings',
                params: { countryCode: '@countryCode' }

            },
            {
                name: 'Airport',
                url: 'application/airports/:airportCode',
                params: { airportCode: '@airportCode' },
                actions: {
                    query: {
                        isArray: false
                    }
                }
            },
            {
                name: 'Allergies',
                url: 'application/:applicationId/allergies',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate('allergyHospitalizationDate'),
                            this.trans.convertStringToCheckbox('allergy', 'allergyAnimals')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('allergy', 'allergyAnimals')
                        ),
                    }
                },

            },
            {
                name: 'PhysicalHealth',
                url: 'application/:applicationId/physicalHealth',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'herpes1LastTime',
                                'visualImpairmentDate',
                                'hearingImpairmentDate',
                                'physicalImpairmentDate'),
                            this.trans.convertStringToCheckbox('allergy')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note')
                        ),
                    }
                }
            },
            {
                name: 'MentalHealth',
                url: 'application/:applicationId/mentalHealth',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'mentalIssuesDate',
                                'medicationsStart',
                                'medicationsEnd',
                                'therapyStart',
                                'therapyEnd',
                                'counsellingStart',
                                'counsellingEnd',
                                'selfHarmStarted',
                                'selfHarmLastTime',
                                'eatingDisorderStarted',
                                'eatingDisorderLastTime'),
                            this.trans.convertStringToCheckbox(
                                'counsellingType',
                                'selfHarmType',
                                'eatingDisorderType',
                                'abuseAndAssaultType')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString(
                                'counsellingType',
                                'selfHarmType',
                                'eatingDisorderType',
                                'abuseAndAssaultType')
                        ),
                    }
                }
            },
            {
                name: 'MedicalTreatments',
                url: 'application/:applicationId/medicalTreatments',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'otherMedicationStart',
                                'hospitalisationDate')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note')
                        ),
                    }
                }
            },
            {
                name: 'OtherHealthConditions',
                url: 'application/:applicationId/otherHealthConditions',
                params: { applicationId: '@applicationId' },
                actions: {
                    get: {
                        transformResponse: this.trans.responseHandler(
                            this.trans.convertToDate(
                                'neurodevelopmentalDate',
                                'medicationsStart',
                                'medicationsEnd',
                                'therapyStart',
                                'therapyEnd'),
                            this.trans.convertStringToCheckbox('neurodevelopmental')
                        )
                    },
                    update: {
                        method: 'PUT',
                        transformRequest: _.flow(
                            this.trans.exclude('note'),
                            this.trans.convertCheckboxToString('neurodevelopmental')
                        ),
                    }
                }
            },
            {
                name: 'HealthReviewStatus',
                url:'',
                params: { applicationId: '@applicationId' },
                actions: {
                    check: {
                        url: 'application/:applicationId/checkAndUpdateHealthReviewStatus',
                        method: 'GET'
                    },
                    canHealthStatus: {
                        url: 'application/:applicationId/canHealthStatus',
                        method: 'GET'
                    }
                }
            },
            {
                name: 'RestrictedHealthInformation',
                url: 'application/:applicationId/restrictedHealthInformation',
                params: { applicationId: '@applicationId' },
                actions: {
                    query: {
                        isArray: false
                    }
                }
            },
            {
                name: 'Vaccinations',
                url: 'application/:applicationId/vaccinations',
                params: { applicationId: '@applicationId' },
                actions: {
                    query: {
                        isArray: false
                    }
                }
            },
            {
                name: 'Gateway',
                url: 'application/gateways/:gatewayCode',
                params: { gatewayCode: '@gatewayCode' },
                actions: {
                    query: {
                        isArray: false
                    }
                }
            },
            {
                name: 'Airline',
                url: 'application/airlines/:code',
                params: { code: '@code' }
            },
            {
                name: 'ReevaluateSection',
                url: 'application/:applicationId/reevaluateSection/:itemId',
                params: { applicationId: '@applicationId', itemId: "@itemId" }
            }
        ]

        this.process(resources)
    }

}

new ApplicationFactory()